import {graphql} from 'gatsby';
import React from 'react';
import {PageContainerDPC, PrintButtonDPC} from "@dpc-fe/shared";
import {prop, getCategoriaPrimariaEN} from "../functions/functions";

export default ({data}) => {

    return (
        <PageContainerDPC basePathNavbar={process.env.PORTALE_URL} classeAggiuntivaSubnavbar={"has-header-subnavbar"}
                          menuItems={data.menu.edges}
                          slugTraduzione={'rss'}
                          title={'RSS'}
                          env={process.env.ENV}
                          breadcrumbItem={{
                              name: 'Rss',
                              slug: 'en/rss',
                              active: true
                          }}
                          lang={'en'}
                          minisiti={prop}
                          menuFooterItems={data.menuFooter.edges}
        >
            <section className="container article-card-simple">
                <h1>RSS</h1>
                <div className="px-custom">
                    <p>Contents of this portal are available also in RSS format (Really Simple Syndication).
                        RSS feeds allow you to receive updates on the latest news and updated content on your computer
                        or mobile device. </p>
                    <div className="it-list-wrapper rss-list">
                        <ul className="it-list">
                            <li><a href={process.env.ENDPOINT_RSS + '?categoria=notizia'} target="_blank" rel="noreferrer">
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-rss"
                                     aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    <path fill-rule="evenodd"
                                          d="M2.5 3.5a1 1 0 0 1 1-1c5.523 0 10 4.477 10 10a1 1 0 1 1-2 0 8 8 0 0 0-8-8 1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1 6 6 0 0 1 6 6 1 1 0 1 1-2 0 4 4 0 0 0-4-4 1 1 0 0 1-1-1z"/>
                                </svg>
                                <span>News</span></a></li>
                            <li><a href={process.env.ENDPOINT_RSS + '?categoria=bollettino_vigilanza'} target="_blank" rel="noreferrer">
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-rss"
                                     aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    <path fill-rule="evenodd"
                                          d="M2.5 3.5a1 1 0 0 1 1-1c5.523 0 10 4.477 10 10a1 1 0 1 1-2 0 8 8 0 0 0-8-8 1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1 6 6 0 0 1 6 6 1 1 0 1 1-2 0 4 4 0 0 0-4-4 1 1 0 0 1-1-1z"/>
                                </svg>
                                <span>National Weather Vigilance Bulletins</span></a></li>
                            <li><a href={process.env.ENDPOINT_RSS + '?categoria=approfondimento'} target="_blank" rel="noreferrer">
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-rss"
                                     aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    <path fill-rule="evenodd"
                                          d="M2.5 3.5a1 1 0 0 1 1-1c5.523 0 10 4.477 10 10a1 1 0 1 1-2 0 8 8 0 0 0-8-8 1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1 6 6 0 0 1 6 6 1 1 0 1 1-2 0 4 4 0 0 0-4-4 1 1 0 0 1-1-1z"/>
                                </svg>
                                <span>Dossier</span></a></li>
                            <li><a href={process.env.ENDPOINT_RSS + '?categoria=bollettino_criticita'} target="_blank" rel="noreferrer">
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-rss"
                                     aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    <path fill-rule="evenodd"
                                          d="M2.5 3.5a1 1 0 0 1 1-1c5.523 0 10 4.477 10 10a1 1 0 1 1-2 0 8 8 0 0 0-8-8 1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1 6 6 0 0 1 6 6 1 1 0 1 1-2 0 4 4 0 0 0-4-4 1 1 0 0 1-1-1z"/>
                                </svg>
                                <span>Criticality Bulletins</span></a></li>
                            <li><a href={process.env.ENDPOINT_RSS + '?categoria=normativa'} target="_blank" rel="noreferrer">
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-rss"
                                     aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    <path fill-rule="evenodd"
                                          d="M2.5 3.5a1 1 0 0 1 1-1c5.523 0 10 4.477 10 10a1 1 0 1 1-2 0 8 8 0 0 0-8-8 1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1 6 6 0 0 1 6 6 1 1 0 1 1-2 0 4 4 0 0 0-4-4 1 1 0 0 1-1-1z"/>
                                </svg>
                                <span>Regulations</span></a></li>
                            <li><a href={process.env.ENDPOINT_RSS + '?categoria=comunicato_stampa'} target="_blank" rel="noreferrer">
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-rss"
                                     aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    <path fill-rule="evenodd"
                                          d="M2.5 3.5a1 1 0 0 1 1-1c5.523 0 10 4.477 10 10a1 1 0 1 1-2 0 8 8 0 0 0-8-8 1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1 6 6 0 0 1 6 6 1 1 0 1 1-2 0 4 4 0 0 0-4-4 1 1 0 0 1-1-1z"/>
                                </svg>
                                <span>Press releases</span></a></li>
                            <li><a href={process.env.ENDPOINT_RSS + '?categoria=foto-e-video'} target="_blank" rel="noreferrer">
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-rss"
                                     aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    <path fill-rule="evenodd"
                                          d="M2.5 3.5a1 1 0 0 1 1-1c5.523 0 10 4.477 10 10a1 1 0 1 1-2 0 8 8 0 0 0-8-8 1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1 6 6 0 0 1 6 6 1 1 0 1 1-2 0 4 4 0 0 0-4-4 1 1 0 0 1-1-1z"/>
                                </svg>
                                <span>Photos and videos</span></a></li>
                        </ul>
                    </div>
                    <PrintButtonDPC/>
                </div>
            </section>
        </PageContainerDPC>
    );
}

export const query = graphql`
  {
        menu: allTaxonomyTermMenuPortaleEn {
          edges {
            node {
              name
              weight
              relationships {
                field_link_menu {
                    ... on node__approfondimento {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__notizia {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__page {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__sezione_senza_anticipazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__sezione_con_anticipazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_approfondimenti {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_podcast {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_notizie_comunicati_stampa {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_glossario {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_pubblicazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_normative {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_bandi_di_gara_e_contratti {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_foto_e_video {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_volontariato {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_rischi {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_ser_nazionale {
                        fields {
                                slug
                           }
                           relationships {
                                field_sottodominio {
                                    name
                                }
                           }
                           drupal_internal__nid
                    }
                    ... on node__homepage_minisito_relazioni {
                        fields {
                                slug
                           }
                           relationships {
                                field_sottodominio {
                                    name
                                }
                           }
                           drupal_internal__nid
                    }
                    ... on node__homepage_minisito_mappe {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_scuola_giovani {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_formazione {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_eventi {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_emergenze {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_domande {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_docs {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_pnrr {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_it_alert {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                }
                parent {
                  name
                  drupal_internal__tid
                }
              }
              drupal_internal__tid
            }
          }
        },
        menuFooter: allTaxonomyTermMenuFooterEn {
          edges {
            node {
              name
              weight
              relationships {
                field_link_menu {
                    ... on node__approfondimento {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__page {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_glossario {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                }
              }
              drupal_internal__tid
            }
          }
        }
  }
`;
